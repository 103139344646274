<template>
  <div>
    <!-- 导航栏 -->
    <div v-show="toolbar.visible & shouldShowToolbar "
         @mouseenter="hoverEnter = true"
         @mouseleave="hoverEnter = false"
         :class="[{ enter: toolbar.enter }, { hoverEnter: (hoverEnter || this.$route.path === '/favorite') && !toolbar.enter }]"
         class="toolbar-content myBetween">
      <!-- 网站名称 -->
      <div class="toolbar-title">
        <h2 @click="$router.push({path: '/'})">{{$store.state.webInfo.webName}}</h2>
      </div>
    </div>
    <div style="text-align: center;">
      <!--  <div class="footer-title"></div>-->
      <iframe :src="game"  v-if="showIframe"
              style="width: 100%;height: 900px ;"
      ></iframe>
    </div>
  </div>

</template>

<script>

export default {
  data(){
    return {
      showIframe: false,
      PcGameList:[1,2,3,4,5,6,7,8],
      WapGameList:[1,2],
      game: 'https://game.eean.cn',
      url: window.location.href,
      hoverEnter: false,
    }
  },
  components: {

  },

  name: "game",
  watch: {
    url(newVal, oldVal) {
      const params = new URLSearchParams(newVal);
      // 获取参数值
      const param1 = params.get('pc');
      const param2 = params.get('wap');
      // 将参数值赋给Vue实例的数据属性
      // this.param1 = param1;
      // this.param2 = param2;
    }
  },
  computed: {
    toolbar() {
      return this.$store.state.toolbar;
    },
    shouldShowToolbar() {
      return this.$route.path.includes('game');
    }
  },

  created() {

    //获取当前url的参数
    let url = window.location.href;
    //获取当前url的参数
    // let index1 = url.substring(url.lastIndexOf('/') + 1, url.length);
    // 获取当前url的参数
    let index2 = url.substring(url.lastIndexOf('?') + 1, url.length);
    // console.log(index1)
    this.game = "https://game.eean.cn"
    this.showIframe = true
    if ('wap'=== index2){
      // 随机获取一个游戏
      let index = Math.floor(Math.random() * this.WapGameList.length);
      this.game = this.game+'/wap/game'+index++;
    }else{
      // 随机获取一个游戏
      let index = Math.floor(Math.random() * this.PcGameList.length);
      this.game = this.game+'/pc/game'+index++;
    }
  },
  mounted() {
    //获取当前url的参数
    let url = window.location.href;
    //获取当前url的参数
    // let index1 = url.substring(url.lastIndexOf('/') + 1, url.length);
    // 获取当前url的参数
    let index2 = url.substring(url.lastIndexOf('?') + 1, url.length);
    // console.log(index1)
    this.game = "https://game.eean.cn"
    this.showIframe = true
    if ('wap'=== index2){
      // 随机获取一个游戏
      let index = Math.floor(Math.random() * this.WapGameList.length);
      this.game = this.game+'/wap/game'+index++;
    }else{
      // 随机获取一个游戏
      let index = Math.floor(Math.random() * this.PcGameList.length);
      this.game = this.game+'/pc/game'+index++;
    }
  },
  methods: {

    handleClose() {
      // 停止音频播放
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }

      // 停止视频播放
      if (this.video) {
        this.video.pause();
        this.video.currentTime = 0;
      }

      // 隐藏或删除iframe中的内容
      this.showIframe = false;
    },

  },

}
</script>

<style scoped>
.top{
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}
/* 两边 */
.myBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toolbar-content {
  width: 100%;
  height: 60px;
  color: var(--white);
  /* 固定位置，不随滚动条滚动 */
  position: fixed;
  z-index: 100;
  /* 禁止选中文字 */
  user-select: none;
  transition: all 0.3s ease-in-out;
}

.toolbar-content.enter {
  background: var(--toolbarBackground);
  color: var(--toolbarFont);
  box-shadow: 0 1px 3px 0 rgba(0, 34, 77, 0.05);
}

.toolbar-content.hoverEnter {
  background: var(--translucent);
  box-shadow: 0 1px 3px 0 rgba(0, 34, 77, 0.05);
}
</style>
